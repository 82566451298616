import React, {useState, useRef} from 'react'
import './index.scss'
import {useTranslation} from "react-i18next";

export default function SectionSeven(){
    const containerRef = useRef(null);
    const {t} = useTranslation()
    const [blockIndex, setBlockIndex] = useState(0)

    const changeSlider = (blockIndex) => {
        containerRef.current.scrollTo({
            left: blockIndex * 895,
        });

        setBlockIndex(blockIndex)
    }

    return (
        <div className="sectionSeven" id="sectionSeven">
            <div className="sectionSeven__title">
                {t('Our Team')}
            </div>
            <div className="sectionSeven__teams" ref={containerRef}>
                <div className="sectionSeven__team">
                    <div className="sectionSeven__team_text">
                        <div>
                            {t('The founder of the platform')} <span>{t('Tigran Amalyan')}</span>,{" "}
                            {t('is a Ukrainian and international businessman who implements innovations in healthcare, renewable energy and other industries.')}
                            <br/>
                            <br/>
                            {t('For the last 15 years his companies have been supplying and integrating into Ukrainian healthcare industry products of the world leading medical equipment manufacturers such as Medtronic, Teruma and many others. Nowadays the range of the supplied products consists of more than 25 thousand items of medical equipment for all health care institutions of Ukraine.')}
                            <br/>
                            <br/>
                            {t('Among the clients are the Ministry of Health of Ukraine, medical education institutions, public and private clinics, and many others.')}
                        </div>
                    </div>
                </div>
                <div className="sectionSeven__team">
                    <div className="sectionSeven__team_text">
                        <div dangerouslySetInnerHTML={{__html: t("COR-Medical Platform team is formed by medical specialists, developers, designers, analysts and experts in the field of information security, united by the common goal - to create better tools for medical professionals and patients. We do whatever it takes to ensure your convenience and security in using our platform.")}} />
                    </div>
                </div>
            </div>
            <div className="sectionSeven__dots">
                <div className={`sectionSeven__dots_dot ${blockIndex === 0 && "active"}`} onClick={() => changeSlider(0)}/>
                <div className={`sectionSeven__dots_dot ${blockIndex === 1 && "active"}`} onClick={() => changeSlider(1)}/>
            </div>
        </div>
    )
}

