import React from 'react'
import styles from './index.scss'
import {useTranslation} from "react-i18next";


export default function Header(){
    const {t} = useTranslation()

    return (
        <div className="header">
            <div className="header__logo">
                <img src="/image/logo.svg" alt="logo"/>
            </div>
            <div className="header__flex">
                <div className="header__navigation">
                    <a href="#sectionTwo" className="header__navigation_nav">{t("Mission")}</a>
                    <a href="#sectionThree" className="header__navigation_nav">{t("About platform")}</a>
                    <a href="#sectionFive" className="header__navigation_nav">{t("Benefits")}</a>
                    <a href="#sectionSix" className="header__navigation_nav">{t("Presentation")}</a>
                    <a href="#sectionSeven" className="header__navigation_nav">{t("About Us")}</a>
                    <a href="#footer" className="header__navigation_nav">{t("Contact")}</a>
                    {/*<button  className="header__btn">*/}
                    {/*    {t("Log in")}*/}
                    {/*</button>*/}
                </div>
            </div>
            <div className="header__burger">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}
