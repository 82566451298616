import React, {useRef, useState} from 'react'
import './index.scss'
import {useTranslation} from "react-i18next";

export default function SectionFive(){
    const containerRef = useRef(null);
    const {t} = useTranslation()
    const [blockIndex, setBlockIndex] = useState(0)


    const changeSlider = (blockIndex) => {
        containerRef.current.scrollTo({
            left: blockIndex * 905,
        });

        setBlockIndex(blockIndex)
    }

    return (
        <div className="sectionFive" id="sectionFive">
            <div className="sectionFive__title">
                {t('Platform Features')}
            </div>
            <div className="sectionFive__content" ref={containerRef}>
                <div className="sectionFive__possibilities">
                    <div className="sectionFive__possibilities_left">
                        <div className="sectionFive__possibilities_title">
                            <img src="/image/section5/item1_mob.png" alt="item"/>
                            {t('For Users')}
                        </div>
                        <div className="sectionFive__possibilities_image">
                            <img src="/image/section5/item1.png" alt="item"  style={{height: 370, width: "auto"}}/>
                        </div>
                    </div>
                    <div className="sectionFive__possibilities_right">
                        <div className="sectionFive__possibilities_itemWrapper">
                            <div className="sectionFive__possibilities_item">
                                <div className="sectionFive__possibilities_item_icon">
                                    <img src="/image/section5/plus.svg" alt="icon"/>
                                </div>
                                <div className="sectionFive__possibilities_item_title">
                                    {t('Saving the whole medical history on your smartphone')}
                                </div>
                            </div>
                            <div className="sectionFive__possibilities_item">
                                <div className="sectionFive__possibilities_item_icon">
                                    <img src="/image/section5/bug.svg" alt="icon"/>
                                </div>
                                <div className="sectionFive__possibilities_item_title">
                                    {t('Synchronization the results of instrumental and laboratory tests of different medical institutions')}
                                </div>
                            </div>
                            <div className="sectionFive__possibilities_item">
                                <div className="sectionFive__possibilities_item_icon">
                                    <img src="/image/section5/bell.svg" alt="icon"/>
                                </div>
                                <div className="sectionFive__possibilities_item_title">
                                    {t('Notifications and reminders about medical check-ups, visits to the doctor, medications, etc.')}
                                </div>
                            </div>
                            <div className="sectionFive__possibilities_item">
                                <div className="sectionFive__possibilities_item_icon">
                                    <img src="/image/section5/message.svg" alt="icon"/>
                                </div>
                                <div className="sectionFive__possibilities_item_title">
                                    {t('Online appointment and telemedicine')}
                                </div>
                            </div>
                            <div className="sectionFive__possibilities_item">
                                <div className="sectionFive__possibilities_item_icon">
                                    <img src="/image/section5/www.svg" alt="icon"/>
                                </div>
                                <div className="sectionFive__possibilities_item_title">
                                    {t('International database available for use by medical institutions in different countries')}
                                </div>
                            </div>
                        </div>
                        <button className="sectionFive__possibilities_btn">
                            {t('View')}
                        </button>
                    </div>
                </div>
                <div className="sectionFive__possibilities">
                    <div className="sectionFive__possibilities_left">
                        <div className="sectionFive__possibilities_title">
                            <img src="/image/section5/item2_mob.png" alt="item"/>
                            {t('For Doctors')}
                        </div>
                        <div className="sectionFive__possibilities_image">
                            <img src="/image/section5/item2.png" alt="item" style={{height: 370, width: "auto"}}/>
                        </div>
                    </div>
                    <div className="sectionFive__possibilities_right">
                        <div className="sectionFive__possibilities_itemWrapper">
                            <div className="sectionFive__possibilities_item">
                                <div className="sectionFive__possibilities_item_icon">
                                    <img src="/image/section5/plus.svg" alt="icon"/>
                                </div>
                                <div className="sectionFive__possibilities_item_title">
                                    {t('Unified and introduced patient anamnesis')}
                                </div>
                            </div>
                            <div className="sectionFive__possibilities_item">
                                <div className="sectionFive__possibilities_item_icon">
                                    <img src="/image/section5/card.svg" alt="icon"/>
                                </div>
                                <div className="sectionFive__possibilities_item_title">
                                    {t('Remote monitoring of the condition of patients, the ability to observe the dynamics')}
                                </div>
                            </div>
                            <div className="sectionFive__possibilities_item">
                                <div className="sectionFive__possibilities_item_icon">
                                    <img src="/image/section5/atom.svg" alt="icon"/>
                                </div>
                                <div className="sectionFive__possibilities_item_title">
                                    {t('Synchronization the results of instrumental and laboratory tests of different medical institutions')}
                                </div>
                            </div>
                            <div className="sectionFive__possibilities_item">
                                <div className="sectionFive__possibilities_item_icon">
                                    <img src="/image/section5/www.svg" alt="icon"/>
                                </div>
                                <div className="sectionFive__possibilities_item_title">
                                    {t('Online system of experience exchange and coordination of the work between doctors of different specialties and countries')}
                                </div>
                            </div>
                            <div className="sectionFive__possibilities_item">
                                <div className="sectionFive__possibilities_item_icon">
                                    <img src="/image/section5/list.svg" alt="icon"/>
                                </div>
                                <div className="sectionFive__possibilities_item_title">
                                    {t("Reduction of 'paper' work and automatization of routine processes")}
                                </div>
                            </div>
                        </div>
                        <button className="sectionFive__possibilities_btn">
                            {t('View')}
                        </button>
                    </div>
                </div>
                <div className="sectionFive__possibilities">
                    <div className="sectionFive__possibilities_left">
                        <div className="sectionFive__possibilities_title">
                            <img src="/image/section5/item3_mob.png" alt="item"/>
                            {t('For the medical industry 2')}
                        </div>
                        <div className="sectionFive__possibilities_image">
                            <img src="/image/section5/item3.png" alt="item"/>
                        </div>
                    </div>
                    <div className="sectionFive__possibilities_right">
                        <div className="sectionFive__possibilities_itemWrapper">
                            <div className="sectionFive__possibilities_item">
                                <div className="sectionFive__possibilities_item_icon">
                                    <img src="/image/section5/www.svg" alt="icon"/>
                                </div>
                                <div className="sectionFive__possibilities_item_title">
                                    {t('Online access to more analytical data of patients and clinics')}
                                </div>
                            </div>
                            <div className="sectionFive__possibilities_item">
                                <div className="sectionFive__possibilities_item_icon">
                                    <img src="/image/section5/pen.svg" alt="icon"/>
                                </div>
                                <div className="sectionFive__possibilities_item_title">
                                    {t('Extensive clinical research capabilities')}
                                </div>
                            </div>
                            <div className="sectionFive__possibilities_item">
                                <div className="sectionFive__possibilities_item_icon">
                                    <img src="/image/section5/box.svg" alt="icon"/>
                                </div>
                                <div className="sectionFive__possibilities_item_title">
                                    {t('Convenient processing of feedback results to improve product quality')}
                                </div>
                            </div>
                            <div className="sectionFive__possibilities_item">
                                <div className="sectionFive__possibilities_item_icon">
                                    <img src="/image/section5/voice.svg" alt="icon"/>
                                </div>
                                <div className="sectionFive__possibilities_item_title">
                                    {t('Creating marketing strategies based on customer needs data')}
                                </div>
                            </div>
                        </div>
                        <button className="sectionFive__possibilities_btn">
                            {t('View')}
                        </button>
                    </div>
                </div>
            </div>
            <div className="sectionFive__dots">
                <div className={`sectionFive__dots_dot ${blockIndex === 0 && "active"}`} onClick={() => changeSlider(0)}/>
                <div className={`sectionFive__dots_dot ${blockIndex === 1 && "active"}`} onClick={() => changeSlider(1)}/>
                <div className={`sectionFive__dots_dot ${blockIndex === 2 && "active"}`} onClick={() => changeSlider(2)}/>
            </div>
        </div>
    )
}
