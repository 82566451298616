export default function enTranslate() {
    return {
        "Mission": "Mission",
        "About platform": "About platform",
        "Benefits": "Benefits",
        "Presentation": "Presentation",
        "About Us": "About Us",
        "Contact": "Contact",
        "Log in": "Log in",
        "Notify us of launch": "Notify us of launch",
        "Mission and goals": "Mission and goals",
        "To take care of health, to improve the quality of and longevity of life": "To take care of health, to improve the quality of and longevity of life",
        "For users": "For users",
        "Implementation of routine check-ups to prevent diseases and their possible complications": "Implementation of routine check-ups to prevent diseases and their possible complications",
        "For doctors": "For doctors",
        "Implementing and improving the protocols for prevention, diagnosis and treatment of diseases": "Implementing and improving the protocols for prevention, diagnosis and treatment of diseases",
        "For the medical industry": "For the medical industry",
        "For the medical industry 2": "For the medical industry",
        "Development / researching / implementation of: diagnostic and treatment facilities, pharmaceuticals, medical devices and equipment": "Development / researching / implementation of: diagnostic and treatment facilities, pharmaceuticals, medical devices and equipment",
        "A new level of health care": "A new level of health care",
        "is a reliable assistant for users. Provides collection, storage, analysis of data for the implementation of medical technology and personalized approach.": "is a reliable assistant for users. Provides collection, storage, analysis of data for the implementation of medical technology and personalized approach.",
        "The global platform connects patients, doctors and the medical industry into a unified ECOSYSTEM. Provides an accessible, standardized algorithm for preventive, diagnostics and treatment.": "The global platform connects patients, doctors and the medical industry into a unified ECOSYSTEM. Provides an accessible, standardized algorithm for preventive, diagnostics and treatment.",
        "The Evolution of Medical Practice": "The Evolution of Medical Practice",
        "Platform Features": "Platform Features",
        "For Users": "For Users",
        "Saving the whole medical history on your smartphone": "Saving the whole medical history on your smartphone",
        "Synchronization the results of instrumental and laboratory tests of different medical institutions": "Synchronization of the results of instrumental and laboratory tests of different medical institutions",
        "Notifications and reminders about medical check-ups, visits to the doctor, medications, etc.": "Notifications and reminders about medical check-ups, visits to the doctor, medications, etc.",
        "Online appointment and telemedicine": "Online appointment and telemedicine",
        "International database available for use by medical institutions in different countries": "International database available for use by medical institutions in different countries",
        "View": "View",
        "For Doctors": "For Doctors",
        "Unified and introduced patient anamnesis": "Unified and introduced patient anamnesis",
        "Remote monitoring of the condition of patients, the ability to observe the dynamics": "Remote monitoring of the condition of patients, the ability to observe the dynamics",
        "Reduction of 'paper' work and automatization of routine processes": "Reduction of 'paper' work and automatization of routine processes",
        "Online system of experience exchange and coordination of the work between doctors of different specialties and countries": "Online system of experience exchange and coordination of work between doctors of different specialties and countries",
        "Online access to more analytical data of patients and clinics": "Online access to more analytical data of patients and clinics",
        "Extensive clinical research capabilities": "Extensive clinical research capabilities",
        "Convenient processing of feedback results to improve product quality": "Convenient processing of feedback results in improved product quality",
        "Creating marketing strategies based on customer needs data": "Creating marketing strategies based on customer needs data",
        'COR-Medical platform launching soon': "COR-Medical platform launching soon",
        'Prepare your medical data, disease information, instrument and clinical test results to upload them to the platform database and get a thorough overview of your health on launch day.': "Prepare your medical data, disease information, instrument and clinical test results to upload them to the platform database and get a thorough overview of your health on launch day.",
        "Sign up for the newsletter to be notified when the platform launches": "Sign up for the newsletter to be notified when the platform launches",
        "Enter your email": "Enter your email",
        "COR-Medical Platform team is formed by medical specialists, developers, designers, analysts and experts in the field of information security, united by the common goal - creating better tools for medical professionals and patients. We do whatever it takes to ensure your convenience and security in using our platform.": "<span>COR-Medical Platform</span> team is formed by medical specialists, developers, designers, analysts and experts in the field of information security, united by the common goal - to create better tools for medical professionals and patients. We do whatever it takes to ensure your convenience and security in using our platform.",
        'Our Team': "Our Team",
        'is a Ukrainian and international businessman who implements innovations in healthcare, renewable energy and other industries.': "is a Ukrainian and international businessman who implements innovations in healthcare, renewable energy and other industries.",
        'For the last 15 years his companies have been supplying and integrating into Ukrainian healthcare industry products of the world leading medical equipment manufacturers such as Medtronic, Teruma and many others. Nowadays the range of the supplied products consists of more than 25 thousand items of medical equipment for all health care institutions of Ukraine.': "For more than 15 years, Tigran Amalyan and his team have been supplying and implementing in Ukraine products from the giants of the global medical industry - Medtronic, Terumо, etc. His company operates under the COR-Medical brand, which has united a number of leading Ukrainian distributors of medical products. In total, the COR-Medical team supplies more than 25,000 medical products to the Ukrainian market and works on its own developments.",
        'Among the clients are the Ministry of Health of Ukraine, medical education institutions, public and private clinics, and many others.': "Among the clients are the Ministry of Health of Ukraine, medical education institutions, public and private clinics, and many others.",
        'The founder of the platform': "The founder of the platform",
        'Tigran Amalyan': "Tygran Amalyan",
        'Ukraine': "Ukraine",
        'city Kiev, 12 Anton Cedik str.': "Kyiv, 12 Anton Cedik str.",
        "Thanks for looking forward to our project. You will receive a notification about the launch of the platform by this email":"Thanks for looking forward to our project. You will receive a notification about the launch of the platform by this email",
        "Email is not valid" : "Email is not valid",
        "Email has already been subscribed": "Email {{ email }} has already been subscribed",
        "project": "project"
    }
}
