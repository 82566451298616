import React, {useEffect, useState} from 'react'
import './index.scss'
import detectLanguage from "../../../utils/detectLanguage";
import {useTranslation} from "react-i18next";
const languageList = ['ru', 'en', 'uk', "zh"]

export default function SectionOne(){
    const {t, i18n} = useTranslation()
    const [languageIndex, setLanguageIndex] = useState(languageList.indexOf(detectLanguage()) + 1)

    const prevImage = () => {
        if(languageIndex === 1){
            return setLanguageIndex(languageList.length)
        }

        setLanguageIndex(languageIndex - 1)
    }

    const nextImage = () => {
        if(languageIndex === languageList.length){
            return setLanguageIndex(1)
        }

        setLanguageIndex(languageIndex + 1)
    }

    useEffect(() => {
        localStorage.setItem("lang_code", languageList[languageIndex - 1])
        i18n.changeLanguage(languageList[languageIndex - 1])
    }, [languageIndex])

    return (
        <div className="sectionOne">
            <div className="sectionOne__switcher">
                <svg onClick={nextImage} width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.737467 6.39023L6.05673 1.05513C6.12005 0.991809 6.18865 0.946849 6.26253 0.920252C6.33641 0.893656 6.41557 0.880569 6.5 0.880991C6.58443 0.880991 6.66359 0.894289 6.73747 0.920886C6.81135 0.947482 6.87995 0.992231 6.94327 1.05513L12.2784 6.39023C12.4261 6.53798 12.5 6.72268 12.5 6.94432C12.5 7.16595 12.4208 7.35593 12.2625 7.51424C12.1042 7.67255 11.9195 7.7517 11.7084 7.7517C11.4974 7.7517 11.3127 7.67255 11.1544 7.51424L6.5 2.85988L1.84565 7.51424C1.69789 7.66199 1.51573 7.73587 1.29916 7.73587C1.08259 7.73587 0.895356 7.65672 0.737467 7.4984C0.579156 7.34009 0.5 7.1554 0.5 6.94432C0.5 6.73323 0.579156 6.54854 0.737467 6.39023Z"
                        fill="#1084FF"/>
                </svg>
                {languageList[languageIndex - 1]}
                <svg onClick={prevImage} width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.2625 1.60965L6.94327 6.94474C6.87995 7.00807 6.81135 7.05303 6.73747 7.07963C6.66359 7.10622 6.58443 7.11931 6.5 7.11889C6.41557 7.11889 6.33641 7.10559 6.26253 7.07899C6.18865 7.0524 6.12005 7.00765 6.05673 6.94474L0.721636 1.60965C0.573879 1.46189 0.5 1.2772 0.5 1.05556C0.5 0.833926 0.579156 0.643953 0.737467 0.485641C0.895778 0.32733 1.08047 0.248175 1.29156 0.248175C1.50264 0.248175 1.68734 0.32733 1.84565 0.485641L6.5 5.14L11.1544 0.485642C11.3021 0.337885 11.4843 0.264006 11.7008 0.264006C11.9174 0.264006 12.1046 0.343161 12.2625 0.501473C12.4208 0.659784 12.5 0.844481 12.5 1.05556C12.5 1.26664 12.4208 1.45134 12.2625 1.60965Z"
                        fill="#7B7B80"/>
                </svg>
            </div>
            <div>
                <video src="/video/video.mp4"  style={{width: "100%"}} autoPlay muted loop={true}/>
            </div>
            {/*<div style={{zIndex: 2, position: "relative", overflow: "hidden"}}>*/}
            {/*    <div className="sectionOne__title1">Сor-Medical<br/> Platform</div>*/}
            {/*    <div className="sectionOne__title2">We CORe about you</div>*/}
            {/*    <a href={"#sectionSix"} className="sectionOne__btn">{t('Notify us of launch')}</a>*/}
            {/*    <div className="sectionOne__runLine">*/}
            {/*        <HorizontalTicker duration={20000} delay={0}>*/}
            {/*            <div>*/}
            {/*                {t('COMING SOON')} {" • "}*/}
            {/*                {t('COMING SOON')} {" • "}*/}
            {/*                {t('COMING SOON')} {" • "}*/}
            {/*                {t('COMING SOON')} {" • "}*/}
            {/*                {t('COMING SOON')} {" • "}*/}
            {/*                {t('COMING SOON')} {" • "}*/}
            {/*                {t('COMING SOON')} {" • "}*/}
            {/*                {t('COMING SOON')} {" • "}*/}
            {/*                {t('COMING SOON')} {" • "}*/}
            {/*                {t('COMING SOON')} {" • "}*/}
            {/*                {t('COMING SOON')} {" • "}*/}
            {/*                {t('COMING SOON')} {" • "}*/}
            {/*                {t('COMING SOON')} {" • "}*/}
            {/*                {t('COMING SOON')} {" • "}*/}
            {/*            </div>*/}
            {/*        </HorizontalTicker>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="sectionOne__image">*/}
            {/*    <img src="/image/item1.png" alt="item"/>*/}
            {/*</div>*/}
        </div>
    )
}
