/**
 * external libs
 */
import axios from 'axios';
/**
 * internal services
 */
import detectLanguage from "../utils/detectLanguage";

axios.interceptors.request.use(async (req) => {
	req.headers.Accept = 'application/json';
	req.headers["Accept-Language"] = `${detectLanguage()};q=1`
	// req.headers.Authorization = 'Bearer ' + StorageService.accessToken;
	// req.headers["Catalog-Type"] = "application/json"
	// req.headers['Api-Key'] = btoa(process.env.REACT_APP_API_KEY);

	return req;
});

axios.interceptors.response.use(
	(response) => response.data,
	async (error) => {
		return error?.response?.data || error;
	},
);

axios.defaults.baseURL = "https://cormed.azurewebsites.net/api";
