import React from 'react'
import './index.scss'
import {useTranslation} from "react-i18next";

export default function SectionTwo(){
    const {t} = useTranslation()
    return (
        <div className="sectionTwo"  id="sectionTwo">
            <div className="sectionTwo__cards">
                <div className="sectionTwo__line">
                    <div className="sectionTwo__line_title">
                        {t('Mission and goals')}:
                    </div>
                    <div className="sectionTwo__line_title2">
                        {t('To take care of health, to improve the quality of and longevity of life')}
                    </div>
                </div>
                <div className="sectionTwo__card">
                    <div className="sectionTwo__card_img">
                        <img src="/image/section2/item1.png" alt="item"/>
                    </div>
                    <div className="sectionTwo__card_content">
                        <div className="sectionTwo__card_title">
                            {t('For users')}
                        </div>
                        <div className="sectionTwo__card_title2">
                            {t('Implementation of routine check-ups to prevent diseases and their possible complications')}
                        </div>
                    </div>
                </div>
                <div className="sectionTwo__card">
                    <div className="sectionTwo__card_img">
                        <img src="/image/section2/item2.png" alt="item"/>
                    </div>
                    <div className="sectionTwo__card_content">
                        <div className="sectionTwo__card_title">
                            {t('For doctors')}
                        </div>
                        <div className="sectionTwo__card_title2">
                            {t('Implementing and improving the protocols for prevention, diagnosis and treatment of diseases')}
                        </div>
                    </div>
                </div>
                <div className="sectionTwo__card">
                    <div className="sectionTwo__card_img">
                        <img src="/image/section2/item3.png" alt="item"/>
                    </div>
                    <div className="sectionTwo__card_content">
                        <div className="sectionTwo__card_title">
                            {t('For the medical industry')}
                        </div>
                        <div className="sectionTwo__card_title2">
                            {t('Development / researching / implementation of: diagnostic and treatment facilities, pharmaceuticals, medical devices and equipment')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
