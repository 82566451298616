/**
 * external libs
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
/**
 * styles
 */
import './general.css';
import './font.css';
/**
 * components
 */
import Site from './pages/web/index'
/**
 * localers
 */
import enTranslate from "./locales/en";
import ruTranslate from "./locales/ru";
import ukTranslate from "./locales/uk";
import zhTranslate from "./locales/zh";
import detectLanguage from "./utils/detectLanguage";
/**
 * helpers
 */
import './helpers/axios';


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: enTranslate()
            },
            uk: {
                translation: ukTranslate()
            },
            ru: {
                translation: ruTranslate()
            },
            zh: {
                translation: zhTranslate()
            },
        },
        lng: detectLanguage(),
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Site />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);
