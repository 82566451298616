import React, {useEffect, useRef, useState} from 'react'
import './index.scss'
import {useTranslation} from "react-i18next";

export default function SectionFour() {
    const {t} = useTranslation()
    const [openVideo, setOpenVideo] = useState(false)
    const videoRef = useRef(null)

    const closeVideo = (e) => {
        if(e.target.className.includes("sectionFour__video")){
            setOpenVideo(false)
        }
    }

    useEffect(() => {
        if(openVideo){
            videoRef.current.play()
        }
    }, [openVideo])


    useEffect(() => {
        const handler = (event) => {
            if (event.key === 'Escape') {
                //if esc key was not pressed in combination with ctrl or alt or shift
                setOpenVideo(false)
            }
        }

        document.addEventListener('keydown', handler);


        return () => document.removeEventListener('keydown', handler);
    })

    return (
        <div className="sectionFour">
            <div className="sectionFour__title">
                {t('The Evolution of Medical Practice')}
            </div>
            <div className="sectionFour__image" onClick={() => setOpenVideo(true)}>
                <img src="/image/section4/item1.png" alt="item1"/>
            </div>
            <div className="sectionFour__image2">
                <img src="/image/section4/item2.png" alt="item1"/>
            </div>
            <div className="sectionFour__image3">
                <img src="/image/section4/item2.png" alt="item1"/>
            </div>
            {openVideo && (
                <div className="sectionFour__video" onClick={closeVideo}>
                    <video src="/image/section4/video.mp4"  controls={true} ref={videoRef}/>
                </div>
            )}
        </div>
    )
}
