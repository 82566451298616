export default function ukTranslate() {
    return {
        "Mission": "Місія",
        "About platform": "Про платформу",
        "Benefits": "Переваги",
        "Presentation": "Презентація",
        "About Us": "Про нас",
        "Contact": "Контакти",
        "Log in": "Вхід",
        "COMING SOON": "НЕЗАБАРОМ",
        "Notify us of launch": "Повідомити про запуск",
        "Mission and goals": "Місія та цілі",
        "To take care of health, to improve the quality of and longevity of life": "Турбота про здоров'я, покращення якості та тривалості життя людини",
        "For users": "Для користувачів",
        "Implementation of routine check-ups to prevent diseases and their possible complications": "Впровадження профоглядів для профілактики захворювань та їхніх можливих ускладнень",
        "For doctors": "Для лікарів",
        "Implementing and improving the protocols for prevention, diagnosis and treatment of diseases": "Впровадження та вдосконалення протоколів профілактики, діагностики та лікування захворювань",
        "For the medical industry": "Для медичної індустрії",
        "Development / researching / implementation of: diagnostic and treatment facilities, pharmaceuticals, medical devices and equipment": "Розробка/дослідження/впровадження: діагностичних та лікувальних установ, лікарських препаратів, виробів медичного призначення та обладнання",
        "A new level of health care": "Новий рівень турботи про здоров'я",
        "is a reliable assistant for users. Provides collection, storage, analysis of data for the implementation of medical technology and personalized approach.": "надійний помічник для користувачів. Забезпечує збір, зберігання, аналіз даних для впровадження медичних технологій та індивідуального підходу",
        "The global platform connects patients, doctors and the medical industry into a unified ECOSYSTEM. Provides an accessible, standardized algorithm for preventive, diagnostics and treatment.": "Глобальна платформа поєднує пацієнтів, лікарів та медичну індустрію в єдину ЕКОСИСТЕМУ. Забезпечує доступний, уніфікований алгоритм профілактики, діагностики та лікування.",
        "The Evolution of Medical Practice": "Еволюція медичної практики",
        "Platform Features": "Можливості платформи",
        "For Users": "Для користувачів",
        "Saving the whole medical history on your smartphone": "Збереження всієї медичної історії у вашому смартфоні",
        "Synchronization the results of instrumental and laboratory tests of different medical institutions": "Синхронізація результатів інструментальних та лабораторних досліджень різних медичних установ",
        "Notifications and reminders about medical check-ups, visits to the doctor, medications, etc.": "Повідомлення та нагадування про профогляди, відвідування лікаря, прийом медикаментів тощо",
        "Online appointment and telemedicine": "Онлайн-запис на прийом та Телемедицина",
        "International database available for use by medical institutions in different countries": "Інтернаціональна база даних, доступна для використання у медзакладах різних країн.",
        "View": "Подивитися",
        "For Doctors": "Для лікарів",
        "Unified and introduced patient anamnesis": "Уніфікований та введений анамнез пацієнта",
        "Remote monitoring of the condition of patients, the ability to observe the dynamics": "Дистанційне спостереження за станом пацієнтів, можливість спостерігати в динаміці",
        "Reduction of 'paper' work and automatization of routine processes": "Скорочення 'паперової' роботи та автоматизація рутинних процесів",
        "Online system of experience exchange and coordination of the work between doctors of different specialties and countries": "Онлайн-система обміну досвідом та координації роботи лікарів різних спеціалізацій та країн",
        "Online access to more analytical data of patients and clinics": "Онлайн-доступ до більшої кількості аналітичних даних пацієнтів та клінік",
        "Extensive clinical research capabilities": "Широкі можливості проведення клінічних досліджень",
        "Convenient processing of feedback results to improve product quality": "Зручна обробка результатів зворотного зв'язку для покращення якості продукції",
        "Creating marketing strategies based on customer needs data": "Побудова маркетингової стратегії на основі даних про потреби клієнтів",
        'COR-Medical platform launching soon': "Запуск платформи COR-Medical вже незабаром",
        'Prepare your medical data, disease information, instrument and clinical test results to upload them to the platform database and get a thorough overview of your health on launch day.': "Підготуйте свої медичні дані, інформацію про захворювання, результати інструментальних та клінічних досліджень, для завантаження їх у базу даних платформи та отримання повної інформації про своє здоров'я вже в день запуску.",
        "Sign up for the newsletter to be notified when the platform launches": "Підпишіться на розсилку, щоб отримати повідомлення про запуск платформи",
        "Enter your email": "Введіть свій email",
        "COR-Medical Platform team is formed by medical specialists, developers, designers, analysts and experts in the field of information security, united by the common goal - to create better tools for medical professionals and patients. We do whatever it takes to ensure your convenience and security in using our platform.": "Команда <span>COR-Medical Platform</span> складається з лікарів-фахівців, розробників, дизайнерів, аналітиків та експертів у сфері інформаційної безпеки, що поєднані спільною метою – створення найкращих інструментів для медиків та пацієнтів. Ми робимо все можливе, щоб забезпечити вашу зручність та безпеку при використанні нашої платформи.",
        'Our Team': "Наша команда ",
        'is a Ukrainian and international businessman who implements innovations in healthcare, renewable energy and other industries.': "український та міжнародний підприємець, який запроваджує інновації у галузі охорони здоров'я, відновлюваної енергетики та інших галузях економіки.",
        'For the last 15 years his companies have been supplying and integrating into Ukrainian healthcare industry products of the world leading medical equipment manufacturers such as Medtronic, Teruma and many others. Nowadays the range of the supplied products consists of more than 25 thousand items of medical equipment for all health care institutions of Ukraine.': "Понад 15 років Тигран Амалян з командою постачає та впроваджує в Україні продукції від гігантів світової медичної індустрії - Medtronic,  Terumо та ін. Його компанія працює під торговою маркою COR-Medical, яка об’єднала низку провідних українських дистриб’юторів медичних товарів. Загалом команда COR-Medical постачає на український ринок понад 25 тис. виробів медичного призначення та працюють над власними розробками.",
        'Among the clients are the Ministry of Health of Ukraine, medical education institutions, public and private clinics, and many others.': "Серед клієнтів – Міністерство охорони здоров'я України, медичні навчальні заклади, державні та приватні клініки та багато інших.",
        'The founder of the platform': "Засновник платформи",
        'Tigran Amalyan': "Тигран Амалян",
        "project": "проєкт",
        'Ukraine': "Україна",
        'city Kiev, 12 Anton Cedik str.': "м. Київ, вул. Антона Цедика, 12",
        "Thanks for looking forward to our project. You will receive a notification about the launch of the platform by this email":"Дякуємо, що з нетерпінням чекаєте на наш проект. На цю електронну пошту ви отримаєте сповіщення про запуск платформи",
        "Email is not valid" : "Електронна адреса недійсна",
        "Email has already been subscribed": "Електронна пошта {{ email }} уже підписана"
    }
}
