export default function zhTranslate() {
    return {
        "Mission": "使命",
        "About platform": "关于平台",
        "Benefits": "优点",
        "Presentation": "介绍",
        "About Us": "关于我们",
        "Contact": "联系",
        "Log in": "Вход",
        "COMING SOON": "即将推出",
        "Notify us of launch": "通知我们此平台推出的消息",
        "Mission and goals": "使命和目标",
        "To take care of health, to improve the quality of and longevity of life": "保养身体，提高生命质量和延长寿命",
        "For users": "为用户",
        "Implementation of routine check-ups to prevent diseases and their possible complications": "提供疾病预防及其潜在并发症的体检推广",
        "For doctors": "为医生",
        "Implementing and improving the protocols for prevention, diagnosis and treatment of diseases": "提供预防，诊断和治疗方案的推广和改进",
        "For the medical industry": "为医疗及健康产业",
        "For the medical industry 2": "用于医疗及健康产业",
        "Development / researching / implementation of: diagnostic and treatment facilities, pharmaceuticals, medical devices and equipment": "开发/研究/推广：诊断和治疗设备、药品，医疗产品和设备",
        "A new level of health care": "医疗保健新水平",
        "is a reliable assistant for users. Provides collection, storage, analysis of data for the implementation of medical technology and personalized approach.": "平台是用户值得信赖的助手。为推广医疗技术和个性化方案收集、存储并分析数据。",
        "The global platform connects patients, doctors and the medical industry into a unified ECOSYSTEM. Provides an accessible, standardized algorithm for preventive, diagnostics and treatment.": "全球化的平台将患者、医生和医疗及健康产业打造成为一个生态系统。 平台会提供一个可访问的，统一的预防、诊断和治疗方案。",
        "The Evolution of Medical Practice": "医疗实践演变",
        "Platform Features": "平台能力",
        "For Users": "为用户",
        "Saving the whole medical history on your smartphone": "将你的整个病历保存在智能手机中",
        "Synchronization the results of instrumental and laboratory tests of different medical institutions": "同步各医疗机构仪器和实验室的研究结果",
        "Notifications and reminders about medical check-ups, visits to the doctor, medications, etc.": "有关体检、就诊、服药等通知和提醒",
        "Online appointment and telemedicine": "网上挂号和远程医疗",
        "International database available for use by medical institutions in different countries": "可供不同国家的医疗机构使用的国际数据库",
        "View": "观看",
        "For Doctors": "为医生",
        "Unified and introduced patient anamnesis": "上传并同步患者的病历",
        "Remote monitoring of the condition of patients, the ability to observe the dynamics": "远端检测患者情况, 持续观察患者的能力",
        "Reduction of 'paper' work and automatization of routine processes": "减少文本工作，并使日常流程自动化",
        "Online system of experience exchange and coordination of the work between doctors of different specialties and countries": "用于交流经验并协调不同专业和国家医生的在线工作系统",
        "Online access to more analytical data of patients and clinics": "在线访问更多患者和诊所的分析数据",
        "Extensive clinical research capabilities": "广泛的临床研究机会",
        "Convenient processing of feedback results to improve product quality": "及时处理反馈结果以便提高产品质量",
        "Creating marketing strategies based on customer needs data": "根据客户需求制定营销策略",
        'COR-Medical platform launching soon': "СOR-Medical 平台即将推出",
        'Prepare your medical data, disease information, instrument and clinical test results to upload them to the platform database and get a thorough overview of your health on launch day.': "准备你的医疗数据，患病信息，仪器和临床研究结果，以便将它们传到平台数据库，并在上传的当天就会收到有关你健康状况的完整信息。",
        "Sign up for the newsletter to be notified when the platform launches": "订阅消息，以便在平台推出时收到通知",
        "Enter your email": "输入你的电子邮箱",
        "COR-Medical Platform team is formed by medical specialists, developers, designers, analysts and experts in the field of information security, united by the common goal - to create better tools for medical professionals and patients. We do whatever it takes to ensure your convenience and security in using our platform.": "<span>COR-Medical</span> 平台团队由医学专家、开发人员、设计师、分析师和信息安全专家组成，他们为了一个共同目标团结在一起, 即为医生和患者创造最好的设备。 我们尽力确保你使用本平台时的便利和安全。",
        "project": "проект",
        'Our Team': "我们的团队",
        'is a Ukrainian and international businessman who implements innovations in healthcare, renewable energy and other industries.': "中文名飞轮，平台创始人，乌克兰和国际企业家。他在医疗保健、可再生能源和其他经济领域引入创新技术。",
        'For the last 15 years his companies have been supplying and integrating into Ukrainian healthcare industry products of the world leading medical equipment manufacturers such as Medtronic, Teruma and many others. Nowadays the range of the supplied products consists of more than 25 thousand items of medical equipment for all health care institutions of Ukraine.': "15 年以来，Tygran Amalyan 和他的团队一直向乌克兰引进和供应全球医疗设备制造行业巨头Medtronic、Terumo 等的产品 。他的公司以 COR-Medical 商标运营，公司联合了多家乌克兰领先的医疗产品经销商。 总体而言，COR-Medical 团队向乌克兰市场供应超过 25,000 种医疗产品，并致力于自身产品开发。",
        'Among the clients are the Ministry of Health of Ukraine, medical education institutions, public and private clinics, and many others.': "客户中有乌克兰卫生部、医学院、公立和私人诊所等。",
        'The founder of the platform': "",
        'Tigran Amalyan': "Tygran Amalyan",
        'Ukraine': "乌克兰",
        'city Kiev, 12 Anton Cedik str.': "基辅市Anton Tsedik’s大街12号",
        "Thanks for looking forward to our project. You will receive a notification about the launch of the platform by this email":"感谢你对我们项目的期待。  你将通过此电子邮件收到关于平台推出的通知。",
        "Email is not valid" : "电子邮件无效",
        "Email has already been subscribed": "{{ email }} - 电子邮件已经订阅"
    }
}
