export default function detectLanguage(){
    if(localStorage.getItem("lang_code")){
        return localStorage.getItem("lang_code")
    }

    let currentLanguage = "en";
    window.navigator.languages.some( language => {
        if(["en", "ru", "uk", "zh"].includes(language)){
            currentLanguage = language
            return true
        }
    })

    localStorage.setItem("lang_code", currentLanguage)

    return currentLanguage;
}

