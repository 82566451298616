import React, {useState} from 'react'
import './index.scss'
import {useTranslation} from "react-i18next";
import axios from "axios";
import { notification } from 'antd';
import { InfoCircleOutlined} from '@ant-design/icons';

export default function SectionSix(){
    const [email, setEmail] = useState("")
    const [emailInvalidText, setEmailInvalidText] = useState("")
    const [api, contextHolder] = notification.useNotification();
    const {t} = useTranslation()


    const openNotification = () => {
        api.open({
            message: t("Thanks for looking forward to our project. You will receive a notification about the launch of the platform by this email"),
            icon: <InfoCircleOutlined style={{ color: '#1084FF' }} />,
        });
    };

    const sendEmail = async () => {
        if(!validateEmail(email)){
            return setEmailInvalidText(t("Email is not valid"))
        }

        const response = await axios.post('https://cormed.azurewebsites.net/api/landing/subscribe', {email})

        if(response.message){
            const [_, email] = response.message.split('"');
            return setEmailInvalidText(t("Email has already been subscribed", {email}))
        }

        openNotification()
        setEmailInvalidText("")
        setEmail("")
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const onChangeEmail = (e) => {
        setEmail(e.target.value.trim())
    }

    const onBlur = () => {
        if(!email || validateEmail(email)){
            return setEmailInvalidText("")
        }

        setEmailInvalidText(t("Email is not valid"))
    }

    return (
        <div className="sectionSix" id="sectionSix">
            {contextHolder}
            <div className="sectionSix__image">
                <img src="/image/section6/item1.png" alt="item"/>
            </div>
            <div className="sectionSix__computer">
                <img src="/image/section6/computer.png" alt="computer"/>
            </div>
            <div className="sectionSix__content">
                <div className="sectionSix__title">
                    {t('COR-Medical platform launching soon')}
                </div>
                <div className="sectionSix__title2">
                    {t('Prepare your medical data, disease information, instrument and clinical test results to upload them to the platform database and get a thorough overview of your health on launch day.')}
                </div>
                <div className="sectionSix__form">
                    <div className="sectionSix__form_title">
                        {t("Sign up for the newsletter to be notified when the platform launches")}
                    </div>
                    <div className="sectionSix__form_line">
                        <div className="sectionSix__form_line_title">
                            {t("Enter your email")}
                        </div>
                        <div className={`sectionSix__form_line_input ${emailInvalidText && "error"}`}>
                            <input type="text"
                                   onBlur={onBlur}
                                   value={email}
                                   onChange={onChangeEmail}
                                   onFocus={() => setEmailInvalidText("")}
                                   placeholder="email"/>
                            {emailInvalidText && (
                                <p className="sectionSix__form_line_error">{emailInvalidText}</p>
                            )}
                        </div>
                        <div className="sectionSix__form_line_message" onClick={sendEmail}>
                            <svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.5056 0.0273438H1.35179C1.10697 0.0273438 0.872184 0.122165 0.699074 0.290948C0.525963 0.459731 0.428711 0.688649 0.428711 0.927344V16.2273C0.428711 16.7047 0.623216 17.1626 0.969437 17.5001C1.31566 17.8377 1.78523 18.0273 2.27486 18.0273H22.5826C23.0722 18.0273 23.5418 17.8377 23.888 17.5001C24.2342 17.1626 24.4287 16.7047 24.4287 16.2273V0.927344C24.4287 0.688649 24.3315 0.459731 24.1583 0.290948C23.9852 0.122165 23.7505 0.0273438 23.5056 0.0273438ZM12.4287 9.60672L3.72525 1.82734H21.1322L12.4287 9.60672ZM9.0491 9.02734L2.27486 15.081V2.97372L9.0491 9.02734ZM10.4152 10.248L11.7999 11.4911C11.9702 11.6435 12.1929 11.7281 12.4241 11.7281C12.6553 11.7281 12.878 11.6435 13.0483 11.4911L14.4329 10.248L21.1252 16.2273H3.72525L10.4152 10.248ZM15.8083 9.02734L22.5826 2.97259V15.0821L15.8083 9.02734Z" fill="white"/>
                            </svg>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
