export default function ruTranslate() {
    return {
        "Mission": "Миссия",
        "About platform": "О платформе",
        "Benefits": "Преимущества",
        "Presentation": "Презентация",
        "About Us": "О нас",
        "Contact": "Контакты",
        "Log in": "Вход",
        "COMING SOON": "ВСКОРЕ",
        "Notify us of launch": "Уведомить о запуске",
        "Mission and goals": "Миссия и цели",
        "To take care of health, to improve the quality of and longevity of life": "Забота о здоровье, повышении качества и продолжительности жизни человека",
        "For users": "Для пользователей",
        "Implementation of routine check-ups to prevent diseases and their possible complications": "Внедрение профосмотров для профилактики заболеваний и их возможных осложнений",
        "For doctors": "Для врачей",
        "Implementing and improving the protocols for prevention, diagnosis and treatment of diseases": "Внедрение и совершенствование протоколов профилактики, диагностики и лечения заболеваний",
        "For the medical industry": "Для медицинской индустрии",
        "For the medical industry 2": "Для медицинской индустрии",
        "Development / researching / implementation of: diagnostic and treatment facilities, pharmaceuticals, medical devices and equipment": "Разработка /исследование / внедрение: диагностических и лечебных учреждений, лекарственных препаратов, изделий медицинского назначения и оборудования",
        "A new level of health care": "Новый уровень заботы о здоровье",
        "is a reliable assistant for users. Provides collection, storage, analysis of data for the implementation of medical technology and personalized approach.": "надежный помощник для пользователей. Обеспечивает сбор, хранение, анализ данных для внедрения медицинских технологий и индивидуального подхода.",
        "The global platform connects patients, doctors and the medical industry into a unified ECOSYSTEM. Provides an accessible, standardized algorithm for preventive, diagnostics and treatment.": "Глобальная платформа объединяет пациентов, врачей и медицинскую индустрию в единую ЭКОСИСТЕМУ. Обеспечивает доступный,  унифицированный алгоритм профилактики, диагностики и лечения.",
        "The Evolution of Medical Practice": "Эволюция медицинской практики",
        "Platform Features": "Возможности платформы",
        "For Users": "Для пользователей",
        "Saving the whole medical history on your smartphone": "Сохранение всей медицинской истории в вашем смартфоне.",
        "Synchronization the results of instrumental and laboratory tests of different medical institutions": "Синхронизация результатов инструментальных и лабораторных исследований различных медицинских учреждений",
        "Notifications and reminders about medical check-ups, visits to the doctor, medications, etc.": "Уведомления и напоминания о профосмотрах, посещении врача, приеме медикаментов и т.д.",
        "Online appointment and telemedicine": "Онлайн-запись на приём и Телемедицина",
        "International database available for use by medical institutions in different countries": "Интернациональная база данных, доступная для использования в медучреждениях разных стран.",
        "View": "Смотреть",
        "For Doctors": "Для врачей",
        "Unified and introduced patient anamnesis": "Унифицированный и введенный анамнез пациента",
        "Remote monitoring of the condition of patients, the ability to observe the dynamics": "Удаленный мониторинг состояния пациентов, возможность наблюдения в динамике",
        "Reduction of 'paper' work and automatization of routine processes": "Сокращение 'бумажной' работы и автоматизация рутинных процессов",
        "Online system of experience exchange and coordination of the work between doctors of different specialties and countries": "Онлайн-система обмена опытом и координации работы врачей разных специализаций и стран",
        "Online access to more analytical data of patients and clinics": "Онлайн-доступ к большему количеству аналитических данных пациентов и клиник",
        "Extensive clinical research capabilities": "Широкие возможности проведения клинических исследований",
        "Convenient processing of feedback results to improve product quality": "Удобная обработка результатов обратной связи для улучшения качества продукции",
        "Creating marketing strategies based on customer needs data": "Построение маркетинговой стратегии на основе данных о потребностях клиентов",
        'COR-Medical platform launching soon': "Запуск платформы COR-Medical уже скоро",
        'Prepare your medical data, disease information, instrument and clinical test results to upload them to the platform database and get a thorough overview of your health on launch day.': "Подготовьте свои медицинские данные, информацию о заболеваниях,  результаты инструментальных и клинических исследований, чтобы загрузить их в базу данных платформы и получить полные сведения о своем здоровье уже в день запуска.",
        "Sign up for the newsletter to be notified when the platform launches": "Подпишитесь на рассылку, чтобы получить уведомление о запуске платформы",
        "Enter your email": "Введите свой email",
        "COR-Medical Platform team is formed by medical specialists, developers, designers, analysts and experts in the field of information security, united by the common goal - to create better tools for medical professionals and patients. We do whatever it takes to ensure your convenience and security in using our platform.": "Команда <span>COR-Medical Platform</span> состоит из врачей-специалистов, разработчиков, дизайнеров, аналитиков и экспертов в сфере информационной безопасности, объединённых общей целью – создание лучших инструментов для медиков и пациентов. Мы делаем все возможное, чтобы обеспечить ваше удобство и безопасность при использовании нашей платформы.",
        "project": "проект",
        'Our Team': "Наша команда",
        'is a Ukrainian and international businessman who implements innovations in healthcare, renewable energy and other industries.': "украинский и международный предприниматель, который внедряет инновации в области здравоохранения, возобновляемой энергетики и других отраслях экономики.",
        'For the last 15 years his companies have been supplying and integrating into Ukrainian healthcare industry products of the world leading medical equipment manufacturers such as Medtronic, Teruma and many others. Nowadays the range of the supplied products consists of more than 25 thousand items of medical equipment for all health care institutions of Ukraine.': "Более 15 лет Тигран Амалян с командой поставляет и внедряет в Украину продукции от гигантов мировой медицинской индустрии - Medtronic, Terumо и т.д. Его компания работает под торговой маркой COR-Medical, объединившей ряд ведущих украинских дистрибьюторов медицинских товаров. В целом, команда COR-Medical поставляет на украинский рынок более 25 тыс. изделий медицинского назначения и работают над собственными разработками.",
        'Among the clients are the Ministry of Health of Ukraine, medical education institutions, public and private clinics, and many others.': "Среди клиентов – Министерство здравоохранения Украины, медицинские учебные заведения, государственные и частные клиники и многие другие.",
        'The founder of the platform': "Основатель платформы",
        'Tigran Amalyan': "Тигран Амалян",
        'Ukraine': "Украина",
        'city Kiev, 12 Anton Cedik str.': "г. Киев, ул. Антона Цедика, 12",
        "Thanks for looking forward to our project. You will receive a notification about the launch of the platform by this email":"Спасибо, что ожидаете наш проект. Вы получить уведомление о запуске платформы по этому email",
        "Email is not valid" : "Email не является допустимым",
        "Email has already been subscribed": "Электронная почта {{ email }} уже подписана"
    }
}
