/**
 * external libs
 */
import React, {Suspense} from 'react';

import Header from "./Header";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import SectionFive from "./SectionFive";
import SectionSix from "./SectionSix";
import SectionSeven from "./SectionSeven";
import Footer from "./Footer";


export default function () {
    return (
        <Suspense fallback={null}>
            <div style={{overflowX: "hidden"}}>
                <Header/>
                <SectionOne/>
                <SectionTwo/>
                <SectionThree/>
                <SectionFour/>
                <SectionFive/>
                <SectionSix/>
                <SectionSeven/>
                <Footer/>
            </div>
        </Suspense>
    );
}





